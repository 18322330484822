import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import DieselTracksMapLargeSVG from '../../svgs/diesel-tracks-map-large'
import DieselTracksStyles from '../../css/pages/diesel-tracks-styles'
import VideoBlock from '../../components/diesel-tracks-video-block'
import ImageCarouselStyles from '../../css/components/image-carousel'
import ImageCarousel from '../../components/image-carousel'
import DieselTracksLineUp from '../../components/diesel-tracks-line-up'
import DieselTracksTextBlock from '../../components/diesel-tracks-text-block'

const CONTINENTS = [
  {
    id: 'africa',
    name: 'Africa',
  },
  {
    id: 'asia',
    name: 'Asia',
  },
  {
    id: 'europe',
    name: 'Europe',
  },
  {
    id: 'nAmerica',
    name: 'North America',
  },
  {
    id: 'sAmerica',
    name: 'South America',
  },
  {
    id: 'oceania',
    name: 'Oceania',
  },
]

const query = graphql`
  query {
    strapiDieselTracks {
      sections
    }
  }
`

const DieselTracks = () => {
  const ConditionalWrapper = ({ condition, wrapper, children }) => {
    return condition ? wrapper(children) : children
  }

  function renderHero(section) {
    return (
      <section
        id="DieselTracksHero"
        className="diesel-tracks__section diesel-tracks__hero"
        key={section.strapi_component}>

        {section.diesel_logo &&
          <img
            className="diesel-tracks__hero-diesel-logo"
            src={section.diesel_logo.url}
            alt="Diesel Logo" />
        }

        {section.bg_image_large &&
          <img
            src={section.bg_image_large.formats.large.url}
            className="diesel-tracks__bg-image diesel-tracks__bg-image--large"
            alt="Diesel Tracks Hero Image" />
        }

        {section.bg_image_small &&
          <img
            src={section.bg_image_small.formats.large.url}
            className="diesel-tracks__bg-image diesel-tracks__bg-image--small"
            alt="Diesel Tracks Hero Image" />
        }

        <div className="diesel-tracks__hero-contents">
          <div className="diesel-tracks__hero-contents-wrapper">
            {section.diesel_tracks_logo &&
              <img
                className="diesel-tracks__hero-diesel-tracks-logo"
                src={section.diesel_tracks_logo.url}
                alt="Diesel Tracks Logo" />
            }

            {section.heading &&
              <h1 className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__hero-heading">
                {section.heading}
              </h1>
            }

            {section.cta_button_label &&
              <button
                id="DieselTracksHeroCta"
                className="nts-button nts-button--outline diesel-tracks__hero-cta">
                <div className="diesel-tracks__hero-cta-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="m11.46 19-1.67-1.67-4.17-4.17 1.74-1.73 3 3v-9.4h2.33l-.08 9.44 3-3 1.73 1.74-4.21 4.22Z" />
                  </svg>
                  <span className="diesel-tracks-font-primary button">
                    {section.cta_button_label}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="m11.46 19-1.67-1.67-4.17-4.17 1.74-1.73 3 3v-9.4h2.33l-.08 9.44 3-3 1.73 1.74-4.21 4.22Z" />
                  </svg>
                </div>
              </button>
            }
          </div>
        </div>
      </section>
    )
  }

  function renderMultiVideosBlock(section) {
    return (
      <section
        id="DieselTracksMultiVideosBlock"
        className="diesel-tracks__section diesel-tracks__multi-videos-block"
        key={section.strapi_component}>
        <div className="diesel-tracks__multi-videos-block-wrapper">
          <h2 className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__multi-videos-block-heading">
            {section.heading}
          </h2>
          {section.body &&
            <div
              className="diesel-tracks-font-primary diesel-tracks__paragraph diesel-tracks__multi-videos-block-body"
              dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
          }
        </div>

        {section.videos.length &&
          <div className="diesel-tracks__video-grid">
            {section.videos.map((video, index) => {
              return (
                <div
                  className="diesel-tracks__video-grid-item video-item"
                  key={index}>
                  <div className="diesel-tracks__video-item">
                    <div id="VideoBlockCover" className="diesel-tracks__video-cover-container">
                      {video.cover_image &&
                        <img
                          className="diesel-tracks__video-cover-image"
                          src={video.cover_image.formats.medium.url}
                          loading="lazy"
                          alt={video.name} />
                      }

                      {video.video_id &&
                        <>
                          <div
                            id="VideoBlockLoading"
                            className="diesel-tracks__video-loading">
                            <svg
                              width="200"
                              height="200"
                              viewBox="0 0 200 200"
                              color="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <defs>
                                <linearGradient id="spinner-secondHalf">
                                  <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
                                  <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                                </linearGradient>
                                <linearGradient id="spinner-firstHalf">
                                  <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
                                  <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                                </linearGradient>
                              </defs>

                              <g strokeWidth="8">
                                <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
                                <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  d="M 4 100 A 96 96 0 0 1 4 98"
                                />
                              </g>

                              <animateTransform
                                from="0 0 0"
                                to="360 0 0"
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                dur="1300ms"
                              />
                            </svg>
                          </div>
                          <button
                            id="VideoBlockPlayButton"
                            className="diesel-tracks__video-play-button visuallyhidden"
                            aria-label="Video Play Button">
                            <div className="diesel-tracks__video-play-icon-container">
                              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 600 600">
                                <g>
                                  <g transform="translate(-772 -385)">
                                    <g transform="translate(0 43)">
                                      <g transform="translate(18)">
                                        <path d="M1260.4 651.3 882.8 861.2c-4.4 2.4-8.2 2.7-11.2 1-3.1-1.7-4.6-5.1-4.6-10.2V433.2c0-4.8 1.5-8.2 4.6-10.2 3.1-2 6.8-1.7 11.2 1l377.6 210c4.4 2.4 6.6 5.3 6.6 8.7 0 3.3-2.2 6.2-6.6 8.6z"/>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </button>
                        </>
                      }
                    </div>

                    {video.video_id &&
                      <div
                        id={`DieselTracksVideoPlayer${section.strapi_component}-${section.id}-${index}`}
                        className="diesel-tracks__video-player"
                        data-youtubeid={video.video_id} />
                    }
                  </div>
                </div>
              )
            })}
          </div>
        }
      </section>
    )
  }

  function renderEpisodes(section) {
    return (
      <section
        className="diesel-tracks__section diesel-tracks__episodes"
        key={section.strapi_component}>
        {(section.heading || section.description) &&
          <header className="diesel-tracks__episodes-header">
            {section.heading &&
              <h2 className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__episodes-heading">
                {section.heading}
              </h2>
            }

            {section.description &&
              <div
                className="nts-font-primary diesel-tracks__paragraph"
                dangerouslySetInnerHTML={{ __html: section.description.replace(/\n/g, '<br />') }}
                style={{ textAlign: 'center' }} />
            }
          </header>
        }

        <div className="diesel-tracks__map diesel-tracks__map--mobile">
          {section.map_image_small &&
            <div className="diesel-tracks__map-wrapper">
              <img
                src={section.map_image_small.url}
                className="diesel-tracks__map-image-mobile" />
            </div>
          }

          <div className="diesel-tracks__map-filter">
            <ul id="DieselTracksMapFilterList" className="nts-list diesel-tracks__map-filter-list">
              {CONTINENTS.map((continent, index) => {
                return (
                  <li className="diesel-tracks__map-filter-item" key={index}>
                    <input
                      id={continent.id}
                      className="diesel-tracks__map-filter-input visuallyhidden"
                      type="checkbox"
                      name={continent.name}
                      value={continent.name} />

                    <label
                      htmlFor={continent.id}
                      className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__map-filter-label"
                      key={index}>
                      {continent.name}
                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="diesel-tracks__map diesel-tracks__map--desktop">
          <div className="diesel-tracks__map-wrapper">
            <DieselTracksMapLargeSVG />
          </div>
        </div>

        <div className="editorial-grid-container">
          <div id="DieselTracksEpisodes" className="editorial-grid" />
        </div>

        <div id="DieselTracksEpisodesLoading" className="diesel-tracks__episodes-loading">
          <span className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__paragraph">
            Loading...
          </span>
        </div>

        <div id="DieselTracksEpisodesError" className="diesel-tracks__episodes-error visuallyhidden">
          <p className="diesel-tracks-font-primary diesel-tracks-heading">
            Something went wrong! Try again later.
          </p>
        </div>
      </section>
    )
  }

  function renderSpotifySection(section) {
    return (
      <section
        className="diesel-tracks__section diesel-tracks__spotify"
        key={section.strapi_component}>
        {section.bg_image_small &&
          <div
            className="diesel-tracks__bg-image diesel-tracks__bg-image--spotify-section diesel-tracks__bg-image--small"
            style={{ backgroundImage: `url(${section.bg_image_small.formats.large.url})` }} />
        }
        {section.bg_image_large &&
          <div
            className="diesel-tracks__bg-image diesel-tracks__bg-image--spotify-section diesel-tracks__bg-image--large"
            style={{ backgroundImage: `url(${section.bg_image_large.formats.large.url})` }} />
        }

        <div className="diesel-tracks__spotify-contents">
          <div className="diesel-tracks__spotify-col">
            <ConditionalWrapper
              condition={!!section.cta}
              wrapper={children => {
                const href = !!section.cta && section.cta.link_url || ''
                const isExternalLink = href.includes('http')
                return (
                  /* eslint-disable react/jsx-no-target-blank */
                  <a
                    href={href}
                    className={!isExternalLink ? 'nts-app' : ''}
                    target={isExternalLink ? '_blank' : '_self'}
                    rel={isExternalLink ? 'noopener noreferrer': ''}
                    aria-label="Spotify Image Link">
                    {children}
                  </a>
                  /* eslint-enable react/jsx-no-target-blank */
                )
              }}>
              {section.image_small &&
                <img
                  src={section.image_small.formats.large.url}
                  className="diesel-tracks__spotify-image diesel-tracks__spotify-image--small"
                  alt="Diesel Tracks Spotify Section Image" />
              }

              {section.image_large &&
                <img
                  src={section.image_large.formats.large.url}
                  className="diesel-tracks__spotify-image diesel-tracks__spotify-image--large"
                  alt="Diesel Tracks Spotify Section Image" />
              }
            </ConditionalWrapper>
          </div>

          <div className="diesel-tracks__spotify-col">
            <div className="diesel-tracks__spotify-text-block">
              {section.heading &&
                <h2 className="diesel-tracks-font-primary diesel-tracks-heading diesel-tracks__spotify-heading">
                  {section.heading}
                </h2>
              }

              {section.body &&
                <div
                  className="diesel-tracks-font-primary diesel-tracks__paragraph"
                  dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
              }

              <ConditionalWrapper
                condition={!!section.cta}
                wrapper={children => {
                  const href = !!section.cta && section.cta.link_url || ''
                  const isExternalLink = href.includes('http')
                  return (
                    /* eslint-disable react/jsx-no-target-blank */
                    <div className="diesel-tracks__spotify-cta-group">
                      <a
                        href={href}
                        className={`diesel-tracks-font-primary nts-button diesel-tracks__spotify-cta ${!isExternalLink ? 'nts-app' : ''}`}
                        target={isExternalLink ? '_blank' : '_self'}
                        rel={isExternalLink ? 'noopener noreferrer': ''}
                        aria-label="Spotify Image Link">
                        {children}
                      </a>
                    </div>
                    /* eslint-enable react/jsx-no-target-blank */
                  )
                }}>
                {section.cta.label}
              </ConditionalWrapper>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function renderVideoBlock(section) {
    return (
      <section
        className="diesel-tracks__section diesel-tracks__section--video-block"
        key={`${section.strapi_component}-${section.id}`}>
        <VideoBlock section={section} />
      </section>
    )
  }

  function renderImageCarousel(section) {
    if (!section.images.length) return

    return (
      <section
        className="diesel-tracks__section"
        key={`${section.strapi_component}-${section.id}`}>
        <ImageCarousel section={section} />
      </section>
    )
  }

  return (
    <>
      <StaticQuery
        query={query}
        render={data => {
          const sections = data.strapiDieselTracks.sections
          if (!sections.length) return null

          return (
            <Layout>
              <link rel="stylesheet" href="https://use.typekit.net/jnb8zaq.css" />
              <style>
                {DieselTracksStyles}
                {ImageCarouselStyles}
              </style>

              <main className="diesel-tracks">
                {sections.map((section) => {
                  switch(section.strapi_component) {
                  case 'diesel-tracks.diesel-tracks-hero':
                    return renderHero(section)
                  case 'diesel-tracks.line-up':
                    return <DieselTracksLineUp section={section} key={section.strapi_component} />
                  case 'components.multi-videos-block':
                    return renderMultiVideosBlock(section)
                  case 'components.text-block':
                    return <DieselTracksTextBlock section={section} key={section.strapi_component} />
                  case 'diesel-tracks.diesel-tracks-episodes':
                    return renderEpisodes(section)
                  case 'diesel-tracks.spotify-section':
                    return renderSpotifySection(section)
                  case 'diesel-tracks.diesel-tracks-video-block':
                    return renderVideoBlock(section)
                  case 'components.image-carousel':
                    return renderImageCarousel(section)
                  default:
                    return null
                  }
                })}
              </main>
            </Layout>
          )
        }}
      />
    </>
  )
}

export default DieselTracks
